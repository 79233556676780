<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button type="button" @click="runBackup" class="btn btn-primary">
            <i class="bi bi-layer-backward"></i>
            Backup</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //
    };
  },
  methods: {
    runBackup() {
      this.$store.dispatch("backup/run");
    },
  },
};
</script>
